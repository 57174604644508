import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { ClipLoader } from 'react-spinners';
import { useTable, useSortBy, useFilters } from 'react-table';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './App.css';
import { FaTimes, FaPlus, FaMinus } from 'react-icons/fa';

const App = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [faqVisibility, setFaqVisibility] = useState({});

  const messages = [
    'Recopilando datos...',
    'Extrayendo información...',
    'Analizando los rivales...',
    'Compilando resultados...',
  ];

  const faqs = [
    {
      question: '¿Qué es fantasystats.org?',
      answer: 'Fantasystats.org es una plataforma de análisis avanzado dedicada a la recopilación de datos de tu liga Fantasy. Ofrecemos herramientas y estadísticas personalizadas que te ayudan a optimizar el rendimiento de tu equipo.',
    },
    {
      question: '¿Por qué es necesario proporcionar mi usuario y contraseña?',
      answer: 'Solicitamos tu usuario y contraseña de manera segura para obtener los datos de tu perfil. Esto nos permite entregarte una visión integral y contextualizada de tu liga, asegurando que los análisis y recomendaciones sean precisos y relevantes para tus necesidades.',
    },
    {
      question: '¿Esta aplicación almacena mis datos personales?',
      answer: 'No, en fantasystats.org nos comprometemos con la privacidad y seguridad de tus datos. No almacenamos información personal ni registros de sesión ni ningún tipo de dato, siguiendo una estricta política de NO LOGS para garantizar que tu información permanezca privada y segura.',
    },
    {
      question: '¿Cómo garantiza fantasystats.org la seguridad de mi información?',
      answer: 'Implementamos protocolos de seguridad de vanguardia, incluyendo encriptación de datos durante la transferencia y medidas de protección avanzadas en nuestros servidores. Nuestro compromiso es mantener tus datos seguros en todo momento.',
    },
    {
      question: '¿Puedo utilizar una contraseña temporal para acceder al servicio?',
      answer: 'Sí, entendemos la importancia de la seguridad. Puedes optar por cambiar tu contraseña y utilizarla en tu cuenta de fantasy para probar nuestro servicio sin comprometer la seguridad de tu cuenta principal.',
    },
    {
      question: '¿Fantasystats.org es compatible con todas las ligas de fantasy?',
      answer: 'Nuestra plataforma es compatible con todas las ligas de Fantasy LaLiga, tanto ligas públicas como privadas.',
    },
  ];
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await axios.post(
        'https://fantasystats.org:8443/api/v1/data_generator',
        {
          username,
          password,
        }
      );

      const fetchedData = response.data;

      if (Array.isArray(fetchedData) && fetchedData.length > 0) {
        setData(fetchedData);
      } else {
        setError('No se encontró información');
      }
    } catch (error) {
      console.error('Error al hacer la petición', error);
      setError('El usuario o la contraseña no son correctos');
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadCSV = () => {
    const csv = Papa.unparse(data);
    const utf8Bom = '\uFEFF';
    const csvWithBom = utf8Bom + csv;
    const leagueName = data[0].league_name
      ? data[0].league_name.trim()
      : 'BigData_Fantasystats.org';
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${leagueName}.csv`);
  };

  const filterTypes = React.useMemo(
    () => ({
      textInclusionExclusion: (rows, id, filterValue) => {
        const { value, invert } = filterValue;

        return rows.filter((row) => {
          const rowValue = row.values[id] ? String(row.values[id]).toLowerCase() : '';
          const searchText = value.toLowerCase();

          if (invert) {
            if (!value) {
              // Excluir filas donde el valor es vacío
              return rowValue !== '';
            }
            // Excluir filas que coinciden con el filtro
            return !rowValue.includes(searchText);
          } else {
            if (!value) {
              // Incluir todas las filas
              return true;
            }
            // Incluir solo filas que coinciden con el filtro
            return rowValue.includes(searchText);
          }
        });
      },
    }),
    []
  );

  // ColumnFilter component
  const ColumnFilter = ({ column }) => {
    const { filterValue = {}, setFilter } = column;
    const { value = '', invert = false } = filterValue;

    return (
      <div className="filter-container">
        <input
          value={value}
          onChange={(e) => setFilter({ value: e.target.value, invert })}
          placeholder={`Filtrar...`}
          className="filter-input"
        />
        <div className="invert-checkbox">
          <label>
            <input
              type="checkbox"
              checked={invert}
              onChange={(e) => setFilter({ value, invert: e.target.checked })}
            />
            Excluir
          </label>
        </div>
      </div>
    );
  };

  const columns = React.useMemo(() => {
    if (data.length > 0) {
      return Object.keys(data[0]).map((key) => {
        let column = {
          Header: key,
          accessor: key,
          Filter: ColumnFilter,
          filter: 'textInclusionExclusion', // Especificamos el filtro personalizado
        };

        // Personaliza la renderización de la celda para columnas de imágenes
        if (key === 'Jugador' || key === 'Equipo') {
          column.Cell = ({ value }) =>
            value ? <img src={value} alt="" /> : 'Sin imagen';
          column.disableFilters = true; // Deshabilita el filtro para columnas de imágenes
          column.disableSortBy = true; // Deshabilita el ordenamiento si no tiene sentido
        }

        // Personaliza la renderización de la celda para columnas numéricas
        if (key === 'Valor' || key === 'Clausula' || key.startsWith('Tendencia')) {
          column.Cell = ({ value }) => {
            const formattedValue = Number(value).toLocaleString('es-ES');
            return `${formattedValue} €`; // Añade el símbolo de euro al final
          };
        }

        return column;
      });
    } else {
      return [];
    }
  }, [data]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
    },
    useFilters,
    useSortBy
  );

  const chartData = React.useMemo(() => ({
    labels: rows.map((row) => row.values.Nombre),
    datasets: [
      {
        label: 'Clausula',
        data: rows.map((row) => {
          const value = Number(row.values.Clausula);
          return isNaN(value) ? 0 : value;
        }),
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        fill: true,
        yAxisID: 'y', // Mismo eje para ambas series
      },
      {
        label: 'Valor',
        data: rows.map((row) => {
          const value = Number(row.values.Valor);
          return isNaN(value) ? 0 : value;
        }),
        borderColor: 'rgba(255,99,132,1)',
        backgroundColor: 'rgba(255,99,132,0.2)',
        fill: true,
        yAxisID: 'y', // Mismo eje para ambas series
      },
    ],
  }), [rows]);

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Telefono horizontal para mayor resolución',
        color: '#fff',
      },
      legend: {
        labels: {
          color: '#fff',
        },
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Clausula (€)',
          color: '#fff',
        },
        ticks: {
          color: '#fff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Valor (€)',
          color: '#fff',
        },
        ticks: {
          color: '#fff',
        },
        grid: {
          drawOnChartArea: false, // Evita superposición de cuadrícula
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      x: {
        ticks: {
          color: '#fff',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
  };

  // Función para alternar la visibilidad de una FAQ
  const toggleFaq = (index) => {
    setFaqVisibility((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="app-wrapper">
      {data.length > 0 ? (
        // Vista de datos con la tabla y el gráfico
        <div className="data-container">
          {/* Botones para descargar CSV y ver gráfico */}
          <div className="button-group">
            <button
              onClick={handleDownloadCSV}
              className="download-button"
            >
              Descargar CSV
            </button>
            <button
              onClick={() => setShowModal(true)}
              className="chart-button"
            >
              Ver gráfico
            </button>
          </div>
          <div className="table-container">
            <table {...getTableProps()} className="data-table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div className="header-content">
                          <span>{column.render('Header')}</span>
                          {/* Botón para ordenar */}
                          {!column.disableSortBy && (
                            <button
                              {...column.getSortByToggleProps()}
                              className="sort-button"
                            >
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? '↓'
                                  : '↑'
                                : '⇅'}
                            </button>
                          )}
                        </div>
                        <div>
                          {column.canFilter ? column.render('Filter') : null}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className={
                            cell.column.id === 'Jugador' ||
                            cell.column.id === 'Equipo'
                              ? 'image-cell'
                              : ''
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* Popup con el gráfico */}
          {showModal && (
            <div className="modal" onClick={() => setShowModal(false)}>
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="close-button"
                  onClick={() => setShowModal(false)}
                >
                  <FaTimes />
                </button>

                {/* Gráfico con ambas columnas */}
                <Line data={chartData} options={chartOptions} />
              </div>
            </div>
          )}
        </div>
      ) : (
        // Formulario inicial
        <div>
        <div className="app-container">
          <div className="container">
            <h1>FANTASY STATS</h1>
            <img
              src="/Fantasystats-background.png"
              alt="Fantasystats Background"
              className="background-image"
              draggable="false"
            />
            <form onSubmit={handleSubmit} className="login-form">
              <div className="form-group">
                <input
                  type="email"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Introduce tu email"
                  required
                  className="form-input"
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Introduce tu contraseña"
                  required
                  className="form-input"
                />
              </div>
              <button
                type="submit"
                disabled={loading}
                className="submit-button"
              >
                Generar datos
              </button>
            </form>
            {loading && (
              <div className="loading-container">
                <ClipLoader size={50} color={'#fff'} loading={loading} />
                <p>{messages[currentMessage]}</p>
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
          </div>
        </div>
        <div className="faq-section">
        <h2>Preguntas Frecuentes</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div
                className="faq-question"
                onClick={() => toggleFaq(index)}
              >
                <span>{faq.question}</span>
                {faqVisibility[index] ? <FaMinus /> : <FaPlus />}
              </div>
              {faqVisibility[index] && (
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
        </div>
      )}      
    </div>
  );
};

export default App;
